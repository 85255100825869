import React, {useState, useEffect} from 'react';
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBCheckbox,
  MDBIcon
}
from 'mdb-react-ui-kit';
import Header from './Header';
import Axios from 'axios'

import {
  faCheck,
  faTimes,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function RegisterUsers() {

  const server_app_api_php = window.SERVER_APP_API_PHP

  const PHONE_REGEX =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
const EMAIL_REGEX = /^[^@]+@[^@]+\.[^@]{2,4}$/

  const[firstName, setFirstName]= useState('')
	const[lastName, setLastName]= useState('')
  const[gender, setGender]= useState('')
	const[manNumber, setManNumber]= useState('')
	const[email, setEmail]= useState('')
	const[phone, setPhone]= useState('')
	const[rank, setRank]= useState('')
	const[position, setPosition]= useState('')

  const [validPhone, setValidPhone] = useState(false)
  const [phoneFocus, setPhoneFocus] = useState(false)
  const [validEmail, setValidEmail] = useState(false)
  const [emailFocus, setEmailFocus] = useState(false)

  useEffect(() => {
    const result = PHONE_REGEX.test(phone)
    setValidPhone(result)
  }, [phone])

  useEffect(() => {
    const result = EMAIL_REGEX.test(email)
    setValidEmail(result)
  }, [email])

  const handleSubmit = async e => {
    //e.preventDefault()
    var formData = new FormData(this)

    formData.append('apiType', 'registerUsers')
    formData.append('firstName', firstName)
    formData.append('lastName', lastName)
    formData.append('gender', gender)
    formData.append('manNumber', manNumber)
    formData.append('email', email)
    formData.append('phone', phone)
    formData.append('rank', rank)
    formData.append('position', position)
   Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(function (response) {
        //handle success
        alert(response.data)
        
        setFirstName('')
        setLastName('')
        setGender('')
        setManNumber('')
        setEmail('')
        setPhone('')
        setRank('')  
        setPosition('')
        
      })
      .catch(function (response) {
        //handle error
        alert("Error: Check Your Internet Connection")
      })
   
  }


  return (
    <MDBContainer fluid className='p-4 background-radial-gradient overflow-hidden'>

 <Header />

      <MDBRow>

        <MDBCol md='5' className='text-center text-md-start d-flex flex-column justify-content-center'>

          <h3 className="my-5 display-6 fw-bold ls-tight px-1" style={{color: 'hsl(218, 81%, 95%)'}}>            
            <span style={{color: 'green'}}>USER REGISTRATION</span>
          </h3>

          <p className='px-3' style={{color: 'hsl(218, 81%, 85%)'}}>
          Register Users for the system.
          </p>

        </MDBCol>

        <MDBCol md='7' className='position-relative'>

          <div id="radius-shape-1" className="position-absolute rounded-circle shadow-5-strong"></div>
          <div id="radius-shape-2" className="position-absolute shadow-5-strong"></div>

          <MDBCard className='my-5 bg-glass'>
            <MDBCardBody className='p-5'>

        <MDBRow>
          <MDBCol col='4'>
            <div class="md-form md-outline">
                <input type="text" class="form-control"  onChange={e => setFirstName(e.target.value)} value={firstName} />
                <label for="f2" class="">First Name</label>
                </div>
                </MDBCol>

                <MDBCol col='4'>
                <div class="md-form md-outline">
                <input type="text" class="form-control"  onChange={e => setLastName(e.target.value)} value={lastName} />
                <label for="f2" class="">Last Name</label>
                </div>
                </MDBCol>
                            
          <MDBCol col='4'>
            <div class="md-form md-outline">
                <input type="text" class="form-control"  onChange={e => setManNumber(e.target.value)} value={manNumber} />
                <label for="f2" class="">Man Number</label>
                </div>
                </MDBCol>

                </MDBRow>

                <MDBRow>
                  
                <MDBCol col='4'>
                
                <div class="md-form">
                <select
                            class='custom-select d-block w-100'        
                            autoComplete='off'
                            onChange={e => setRank(e.target.value)}
                            value={rank}
                            required                            
                          >
              <option value=''>Choose Rank....</option>
              <option >General</option>
							<option >Leutenant General</option>
							<option >Major General</option>
							<option >Brigadier General</option>
							<option >Colonel</option>
							<option >Leutenant Colonel</option>
							<option >Major</option>
							<option >Captain</option>
							<option >Leutenant</option>
							<option >2nd Leutenant</option>	
              <option>Warrant Officer I</option>
              <option>Warrant Officer II</option>
              <option>Flight Surgent</option>
              <option>Surgent</option>
              <option>Corporal</option>
                          </select>
                </div>
                </MDBCol>

                <MDBCol col='4'>
                <div class="md-form md-outline">
                <select
                            class='custom-select d-block w-100'        
                            autoComplete='off'
                            onChange={e => setPosition(e.target.value)}
                            value={position}
                            required                            
                          >
                         <option value=''>Choose Position....</option>                         
                         <option>Chief Of Operations</option>
                         <option>Vice Chief Of Operations</option>
                         <option>DATS</option>
                         <option>Director Ground Defence</option>
                         <option>Director Air Defence</option>
                         <option>D UAVs</option>
                         <option>Director OPS Standards</option>
                         <option>DUNO</option>
                         <option>CATCO (CAA)</option>
                         <option>Director Air Int</option>
                         <option>D OPS Training</option>
                         <option>DDATS</option>
                         <option>Ag D Strike Operations</option>
                         <option>DAS</option>
                         <option>DDATO</option>
                         <option>SOO</option>
                         <option>DD OPS Admin</option>
                         <option>ON Course</option>
                         <option>DDAD</option>
                         <option>DDGD</option>
                         <option>D DAI</option>
                         <option>DD UAVS</option>
                         <option>DD DOTS</option>
                         <option>D SOO</option>
                         <option>D DAS</option>
                         <option>D DOS</option>
                         <option>DD DUNO</option>
                         <option>OPS Assistant</option>
                         <option>OPS ASST GRD</option>
                          </select>
                </div>
                </MDBCol>

                <MDBCol col='4'>
                <div class="md-form md-outline">
                <select
                            class='custom-select d-block w-100'        
                            autoComplete='off'
                            onChange={e => setGender(e.target.value)}
                            value={gender}
                            required                            
                          >
                         <option value=''>Choose Gender....</option>                         
                         <option>Male</option>
                         <option>Female</option>
                        
                          </select>
                </div>
                </MDBCol>

              </MDBRow>
              <MDBRow>
          <MDBCol col='6'>
            <div class="md-form md-outline">
                <input type="text" class="form-control"  onChange={e => setEmail(e.target.value)} value={email} 
                       onFocus={() => setEmailFocus(true)}
                       onBlur={() => setEmailFocus(false)}
                     />
                     <p
                   className='red-text'
                       hidden={
                           emailFocus === false || validEmail === true
                       }
                     >
                       <FontAwesomeIcon icon={faInfoCircle} />
                       4 to 24 characters. <br />
                       Let It Be A Valid Email Address.
                     </p>
                <label for="f2" class="">Email</label>
                </div>
                </MDBCol>

                <MDBCol col='6'>
                <div class="md-form md-outline">
                <input type="text" class="form-control"   onChange={e => setPhone(e.target.value)} value={phone} 
                  onFocus={() => setPhoneFocus(true)}
                  onBlur={() => setPhoneFocus(false)}
                />
                <p
                  className='red-text'
                  hidden={
                      phoneFocus === false || validPhone === true
                  }
                >
                  <FontAwesomeIcon icon={faInfoCircle} />
                  there should be 10 or more, but less than 14
                  numerical values. <br />
                  You can also add + followed by your country or area
                  phone number code.
                </p>
                <label for="f2" class="">Phone</label>
                </div>
                </MDBCol>
              </MDBRow>
              

                <div className='row'>
              <button className='col-12 btn btn-primary' 
   onClick={handleSubmit} disabled={
    firstName === '' || lastName === '' || gender === '' || manNumber === '' || rank === '' || position === '' || 
    phone === '' || email === '' || !email === '' || !phone === ''                   
    }>
    Register
                </button>
                </div>

             </MDBCardBody>
          </MDBCard>

        </MDBCol>

      </MDBRow>

    </MDBContainer>
  );
}

export default RegisterUsers;