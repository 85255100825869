import React, {useState, useEffect} from 'react';
import Header from './Header';
import Axios from 'axios'

const App = () => {
  const server_app_api_php = window.SERVER_APP_API_PHP
  let [handleSubmitBtn, setHandleSubmitBtn] = useState('')

  let[manNumber, setManNumber] = useState("")
  let[rank, setRank] = useState("")
  let[firstName, setFirstName] = useState("")
  let[lastName, setLastName] = useState("")
  let[email, setEmail] = useState("")
  let[phone, setPhone] = useState("")
  let[office, setOffice] = useState("")
  let[gender, setGender] = useState("")

  let ID = localStorage.getItem('userID')
  ID = JSON.parse(ID)
  
  useEffect(() => {
    setHandleSubmitBtn('Loading');
var formData = new FormData(this)

formData.append('apiType', 'getViewProfile')
formData.append('id', ID)
Axios({
  method: 'post',
  url: `${server_app_api_php}/reactphp/index.php`,
  data: formData,
  headers: { 'Content-Type': 'multipart/form-data' }
})
  .then(function (response) {
    //handle success
   //alert(response.data)
    setHandleSubmitBtn('');
   //setDBtableView(response.data)
   setManNumber(response.data.manNumber)
   setRank(response.data.position.toUpperCase())
   setFirstName(response.data.firstName.toUpperCase())
   setLastName(response.data.lastName.toUpperCase())
   setEmail(response.data.email)
   setPhone(response.data.phone)
   setOffice(response.data.office.toUpperCase())
   setGender(response.data.gender.toUpperCase())
  })
  .catch(function (response) {
    //handle error
    alert("Error: Check Your Internet Connection")
  })
}, [])

  return (
    <>


      {/* Main layout */}

    <main>
      <div className="container-fluid">
        {/* Section: Team v.1 */}
<header className='mb-5'>
        <Header />
        </header>
        <section className="section team-section mt-5">
          {/* Grid row */}
          <div className="row text-center mt-5">

          <div hidden={handleSubmitBtn ===''}>
    <div id='overlayer'></div>
    <div class="loader d-flex justify-content-center">
  <div class="spinner-grow text-primary" style={{width: '10rem', height: '10rem'}} role="status">
      <span class='visually-hidden'>Loading...</span>
    </div>
  </div>
  </div>

             {/* Grid column */}
             <div className="col-md-12 mb-12 mt-3">
              {/* Card */}
              <div className="card profile-card">
                {/* Avatar */}
               
                <div className="card-body pt-0 mt-3">
                  {/* Name */}
                  <h3 className="mb-0 font-weight-bold"><strong>YOUR PROFILE</strong></h3>
                </div>

              </div>
              {/* Card */}
            </div>
            {/* Grid column */}

             {/* Grid column */}
             <div className="col-md-4 mb-4 mt-5">
              {/* Card */}
              <div className="card profile-card">
                {/* Avatar */}
               
                <div className="card-body pt-0 mt-0">
                  {/* Name */}
                  <h3 className="mb-3 font-weight-bold"><strong>{rank}</strong></h3>
                  <h6 className="font-weight-bold cyan-text mb-4">{lastName} {firstName}</h6>
                </div>
              </div>
              {/* Card */}
            </div>
            {/* Grid column */}

            {/* Grid column */}
            <div className="col-md-8 mb-4 mt-5">
              {/* Card */}
              <div className="card card-cascade cascading-admin-card user-card">
                {/* Card Data */}
                
                {/* Card Data */}
                {/* Card content */}
                <div className="card-body card-body-cascade">
                  {/* Grid row */}
                  <div className="row">
                    {/* Grid column */}
                    <div className="col-lg-6">
                      <div className="md-form form-sm mb-0">
                        
                      <label htmlFor="form12" className="">Man Number</label> <br />
                        <input type="text" id="form12" className="form-control form-control-sm" value={manNumber} disabled />
                      </div>
                    </div>
                    {/* Grid column */}
                    {/* Grid column */}
                    <div className="col-lg-6">
                      <div className="md-form form-sm mb-0">                        
                      <label htmlFor="form4" className="disabled">Rank</label> <br />
                        <input type="text" id="form4" className="form-control form-control-sm" value={rank} disabled />
                      </div>
                    </div>
                    {/* Grid column */}
                  </div>
                  {/* Grid row */}
                  {/* Grid row */}
                  <div className="row">
                    {/* Grid column */}
                    <div className="col-md-6">
                      <div className="md-form form-sm mb-0">
                        <label htmlFor="form5" className="">First name</label> <br />
                        <input type="text" id="form5" className="form-control form-control-sm" value={firstName} disabled />
                      </div>
                    </div>
                    {/* Grid column */}
                    {/* Grid column */}
                    <div className="col-md-6">
                      <div className="md-form form-sm mb-0">
                        <label htmlFor="form5" className="">Last name</label> <br />
                        <input type="text" id="form5" className="form-control form-control-sm" value={lastName} disabled />
                      </div>
                    </div>
                    {/* Grid column */}
                  </div>
                  {/* Grid row */}
                 
                  {/* Grid row */}
                  <div className="row">
                    {/* Grid column */}
                    <div className="col-lg-6 col-md-6">
                      <div className="md-form form-sm mb-0">
                        <label htmlFor="form8" className="">Email</label> <br />
                        <input type="text" id="form8" className="form-control form-control-sm" value={email} disabled />
                      </div>
                    </div>
                    {/* Grid column */}
                    {/* Grid column */}
                    <div className="col-lg-6 col-md-6">
                      <div className="md-form form-sm mb-0">
                        <label htmlFor="form9" className="">Phone</label> <br />
                        <input type="text" id="form9" className="form-control form-control-sm" value={phone} disabled />
                      </div>
                    </div>
                    {/* Grid column */}
                  </div>
                  {/* Grid row */}

                   {/* Grid row */}
                   <div className="row">                    
                    {/* Grid column */}
                    <div className="col-lg-10 col-md-12">
                      <div className="md-form form-sm mb-0">
                        <label htmlFor="form7" className="">Office</label> <br />
                        <input type="text" id="form7" className="form-control form-control-sm" value={office} disabled />
                      </div>
                    </div>
                    {/* Grid column */}
                    {/* Grid column */}
                    <div className="col-lg-2 col-md-12">
                      <div className="md-form form-sm mb-0">
                        <label htmlFor="form6" className="">Gender</label> <br />
                        <input type="text" id="form6" className="form-control form-control-sm" value={gender}  disabled />
                      </div>
                    </div>
                    {/* Grid column */}
                  </div>
                  {/* Grid row */}

                </div>
                {/* Card content */}
              </div>
              {/* Card */}
            </div>
            {/* Grid column */}
           
          </div>
          {/* Grid row */}
        </section>
        {/* Section: Team v.1 */}
      </div>
    </main>
      {/* Main layout */}
    </>
  );
}

export default App;
