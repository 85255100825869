import React from 'react';
import ReactDOM from 'react-dom';
import Header from './Header';
/*import $ from './modules/js/jquery-3.4.1.min.js';
import './modules/js/popper.min.js';
import './modules/js/bootstrap.min.js';
import './modules/js/mdb.min.js';*/
import $ from 'jquery';
//import sideNav from './js/Sidenav'
function Dashboard() {
    //$(".button-collapse").sideNav();

    var container = document.querySelector('.custom-scrollbar');
    /*var ps = new PerfectScrollbar(container, {
      wheelSpeed: 2,
      wheelPropagation: true,
      minScrollbarLength: 20
    });*/
    
    return(
      <main>
        <div class = "container">
        <section class="">
          <div class="row">
            <div class='col-10'>
   <Header />
   </div>

</div>
    </section>
    </div>

    </main>
    );
    
    }
    
    export default Dashboard;