//import logo from './logo.svg';
//import './App.css';
import { useRef, useState, useEffect, useContext } from 'react'
//import AuthContext from './context/AuthProvider'
import {  MDBInput,  MDBBtn,  MDBCheckbox,  MDBRow,  MDBCol,  MDBIcon,  MDBInputGroup,  MDBNavbar,  MDBContainer
} from 'mdb-react-ui-kit';
import WOW from 'wowjs';
import { useNavigate, Navigate, Routes } from 'react-router-dom'

import Footer from './Footer'
import axios from 'axios'

function Welcome() {

  
  let navigate = useNavigate()
  const handleForgotPassword = async e => {
    navigate('/ForgotPassword')
  }

  const server_app_api_php = window.SERVER_APP_API_PHP


  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);

  const [changePassword, setChangePassword] = useState(true);
  const changeIcon = changePassword === true ? false : true;
  var fontSize = '200%';
  var color = 'red';
  var eyeIcon = 'fa fa-eye';
  if(changeIcon === true){
	  fontSize = '200%';
	  color = 'black';
    eyeIcon = 'fa fa-eye-slash';
  }

  localStorage.removeItem('userID')
  localStorage.removeItem('userPosition')
  localStorage.removeItem('rank')
  localStorage.removeItem('manNumber')
  localStorage.removeItem('name')
  localStorage.removeItem('login')
  localStorage.removeItem('loggedIn') 

  //const { setAuth } = useContext(AuthContext)
  const userRef = useRef()
  const errRef = useRef()

  const [user, setUser] = useState('')
  const [pwd, setPwd] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [success, setSuccess] = useState(false)
  //let navigate = useNavigate()
  //const [data, setData] = useState([]);
  const [data, setData] = useState('Iam Zambian')
  let [handleSubmitBtn, setHandleSubmitBtn] = useState('')
  let [handleCheckConnectivity, setHandleCheckConnectivity] = useState('')
  useEffect(() => {
new WOW.WOW({
  live:false
}).init();
  }, [])

  const handleSubmit = async e => {
    setHandleSubmitBtn('Loading')
    var formDataLogin = new FormData(this)
    formDataLogin.append('user', user)
    formDataLogin.append('pwd', pwd)
    axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/login.php`,
      data: formDataLogin,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(res => {
        setHandleSubmitBtn('')
        //alert(res.data);  
        if(res.data.loggedIn === "Yes"){

          if(res.data.login === "Admin"){
            localStorage.setItem('userID', JSON.stringify(res.data.userID))
            localStorage.setItem('userPosition', JSON.stringify(res.data.userPosition))
            localStorage.setItem('rank', JSON.stringify(res.data.rank))
            localStorage.setItem('manNumber', JSON.stringify(res.data.manNumber))
            localStorage.setItem('name', JSON.stringify(res.data.name))
            localStorage.setItem('login', JSON.stringify(res.data.login))
            localStorage.setItem('loggedIn', JSON.stringify(res.data.loggedIn))            
            navigate('/Dashboard')
          }
          else if(res.data.login === "User"){
            localStorage.setItem('userID', JSON.stringify(res.data.userID))
            localStorage.setItem('userPosition', JSON.stringify(res.data.userPosition))
            localStorage.setItem('rank', JSON.stringify(res.data.rank))
            localStorage.setItem('manNumber', JSON.stringify(res.data.manNumber))
            localStorage.setItem('name', JSON.stringify(res.data.name))
            localStorage.setItem('login', JSON.stringify(res.data.login))
            localStorage.setItem('loggedIn', JSON.stringify(res.data.loggedIn))            
            navigate('/Dashboard')

          }
        }   
        else if(res.data === "User Does Not Exist"){
alert("Error : Wrong Credentials");
        }
      })
      .catch(err => {alert('ERROR : Kindly Contact Your Administrator')
      setHandleSubmitBtn('')
    })
    //.catch((err) => alert(err));
  }

 


  return (
 
<>
      <title>
       ZAF OPS
      </title>
      
      {/*<!-- Preloader -->*/}
      <div hidden={handleCheckConnectivity ===''}>
        <div id='overlayer'></div>
        <div class="loader d-flex justify-content-center">
        <div class="spinner-border text-danger" style={{width: '4rem', height: '4rem'}} role="status">
</div>
      <div class="spinner-grow text-primary" style={{width: '4rem', height: '4rem'}} role="status">
            <span class='visually-hidden'>Loading...</span>
          </div>
        </div>
        </div>

      <section class='view intro-2' id='login'>
        <div>
          <div class='container'>
            <div class='row'>
              <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto mt-5'>
                <div class='card wow fadeIn' data-wow-delay='0.3s'>
                  <div
                    class='card-body'
                    id='card-body'
                    style={{ background: 'transparent' }}
                  >
                    <div class='form-header mdb-color lighten-0 red'>
                      <h3 class='font-weight-500 my-2 py-1'>
                        ZAMBIA AIR FORCE
                      </h3>
                    </div>

                    {/*</form>*/}
                    {/*< -- Grid row --> */}
                    <div class='row mt-5'>
                      {/*< -- Grid column --> */}
                      <div class='col-md-12 ml-lg-12 ml-md-12'>
                        {/*< -- Grid column --> */}
                        <div class='col-xl-12 col-md-6'>
                          {/*< -- Panel --> */}
                          <div
                            class='card wow fadeInLeft'
                            data-wow-delay='1s'
                          >
                            {/*<div
                              class='card-header white-text'
                              style={{ backgroundColor: '#0C3823' }}
                            >
                              DETAILS
                            </div> */}

                            {/*< -- Card Data --> */}

                            {/*< -- Card content --> */}
                            <div class='card-body'>
                              <div class='text-center'>
                                {/*< -- Grid row --> */}
                                <div class='row'>
                                  <div class='col-12 col-lg-12'>
                                    {/*< --<i class="fa fa-company indigo-text fa-lg"></i>-->*/}
                                  </div>
                                  <div class='col-12'>
                                    <h5
                                      class='font-weight-bold mb-1'
                                      style={{ color: 'blue' }}
                                    >
                                      <strong>
                                        
                                          OPERATIONS DEPARTMENT
                                        
                                      </strong>
                                    </h5>
                                    <p></p>
                                  </div>
                                </div>
                                {/*< -- Grid row --> */}

                                {/*< -- Grid row --> */}
                                <div class='row '>
                                  <div class='col-12 col-lg-12'>
                                    {/*< --<i class="fa fa-company indigo-text fa-lg"></i>-->*/}
                                  </div>
                                  <div class='col-12'>
                                    <h5
                                      class='font-weight-bold mb-1'
                                      style={{ color: 'blue' }}
                                    >
                                      <strong>
                                        INFORMATION SHARING SYSTEM
                                      </strong>
                                    </h5>
                                  </div>
                                </div>
                                {/*< -- Grid row --> */}
                              </div>
                            </div>
                            {/*< -- Card content --> */}
                          </div>
                          {/*< -- Panel --> */}
                        </div>
                        {/*< -- Grid column --> */}
                      </div>
                      {/*< -- Grid column --> */}

                      {/*< -- Grid column --> */}
                      <div
                        class='col-md-12 wow fadeInRight'
                        data-wow-delay='3s'                        
                      >
                        

                        {/*< -- Body --> */}
                        <MDBRow tag="form" className='g-3 text-left'>
                        <MDBCol md="12">
                        <label>Username</label>
                        <MDBInputGroup textBefore={<i class='fa fa-envelope prefix red-text'></i>} className='mb-3'>
        <input ref={userRef} className='form-control' type='text' onChange={e => setUser(e.target.value)} value={user} />
      </MDBInputGroup>
      </MDBCol>
      </MDBRow>

                        <MDBRow tag="form" className='g-3 text-left'>
                        <MDBCol md="12">
                          <label>Password</label>                     
      <MDBInputGroup className='mb-3' textBefore={<i class='fa fa-lock prefix red-text'></i>} textAfter= {showPassword ? <MDBIcon icon='eye-slash' style={{fontSize:'100%', color:'black'}} type='button' onClick={handleShowPassword}/> : <MDBIcon icon='eye' type='button' onClick={handleShowPassword} style={{fontSize:'100%', color:'red'}}/> }>
        <input type={showPassword ? "text" : "password"} className='form-control' onChange={e => setPwd(e.target.value)} value={pwd} />
      </MDBInputGroup>
      </MDBCol>
                   
                     </MDBRow>

                        <div class='text-center'>
                          <button
                            class='btn btn-primary mt-1'
                            onClick={handleSubmit}
                            disabled={user === '' || pwd === '' || handleSubmitBtn === 'Loading'}
                          >
                    <div class="spinner-border" role="status" hidden = {handleSubmitBtn === ''}>
</div>
                            <span class='fa fa-sign-in'> Login</span>
                          </button>
                          <br />

                          <a onClick={handleForgotPassword} class='red-text'>
                            {' '}
                            <i class='w-fa fa fa-key'></i>Forgot Password
                          </a>
                        </div>
                      </div>
                      {/*< -- Grid column --> */}
                    </div>
                    {/*< -- Grid row --> */}
                  </div>{' '}
                  {/*Card Body Ends Here*/}
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </section>
    <br />
  
  
    <footer class="page-footer pt-0 mt-2 mdb-color light-5 blue" id="footer"  >
<div class="footer-copyright py-4 text-center">
        <div class="container-fluid">
      Powered By : <span style={{color: 'white' }}> SOKAMSANI </span>
        </div>
      </div>
    </footer>

</>



  );
}

export default Welcome;
