import React, {useState, useEffect} from 'react';
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBCheckbox,
  MDBIcon,
  MDBFile,
  MDBTable, MDBTableHead, MDBTableBody
}
from 'mdb-react-ui-kit';
import { MDBDataTable } from "mdbreact";
import Header from './Header';
import Axios from 'axios'

import {
  faCheck,
  faTimes,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function ViewUsers() {

  const server_app_api_php = window.SERVER_APP_API_PHP

  let [handleSubmitBtn, setHandleSubmitBtn] = useState('')

  const [rows, setDBtableView] = useState([])
    const source = 1;

  
	
    useEffect(() => {
        setHandleSubmitBtn('Loading');
    var formData = new FormData(this)

    formData.append('apiType', 'viewUsers')
    formData.append('id', source)
   Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(function (response) {
        //handle success
       //alert(response.data)
        setHandleSubmitBtn('');
       setDBtableView(response.data)
      })
      .catch(function (response) {
        //handle error
        alert("Error: Check Your Internet Connection")
      })
   
    }, [])


  let data = {
    columns: [
        {
            label: "SN",
            field: "sn"
          },
          {
            label: "Rank",
            field: "rank",
            sort: "asc",
            width: 270
          },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150
      }
      ,
      {
        label: "Position",
        field: "position",
        sort: "asc",
        width: 150
      }
      ,
      {
        label: "Man Number",
        field: "man_number",
        sort: "asc",
        width: 150
      }      
      ,
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 150
      }
      ,
      {
        label: "Phone",
        field: "phone",
        sort: "asc",
        width: 150
      }
    ],
    rows: rows  
  };

  rows.map( (item,index) => {
    if(item.counter != '0'){
    item.sn = (
<div>
    {index +1}
</div>
    );
  }
  });

  rows.map( (item,) => {
    if(item.counter != '0'){
    item.messagesID = (
<div>
   
                                  <span 
                                  onClick={() => {
                                    downloadDoc(item.item)
                                  }}
                                  type='button'
                                  class='fa fa-download green-text'
                                  title='Download Document'
                                ></span>
                                
</div>
    );
 }
  });
  
  const downloadDoc = file => {
    window.open(`${server_app_api_php}/reactphp/messages docs/${file}`)
}
 


  return (
    <MDBContainer fluid className='p-4 background-radial-gradient overflow-hidden'>

 <Header />

      <MDBRow>

      <div hidden={handleSubmitBtn ===''}>
    <div id='overlayer'></div>
    <div class="loader d-flex justify-content-center">
  <div class="spinner-grow text-primary" style={{width: '10rem', height: '10rem'}} role="status">
      <span class='visually-hidden'>Loading...</span>
    </div>
  </div>
  </div>

  

        <MDBCol md='12' className='position-relative text-center text-md-start d-flex flex-column justify-content-center'>

          <MDBCard className='my-5 bg-glass'>
            <MDBCardBody className='p-5'>
     
            <MDBDataTable striped bordered hover small data={data} selectRows={true}  />

             </MDBCardBody>
          </MDBCard>

        </MDBCol>

      </MDBRow>

    </MDBContainer>
  );
}

export default ViewUsers;