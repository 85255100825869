import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import Sidebar from './Sidebar';



function Header() {

  const [isOpen, setIsOpen] = useState(false);

    return(
  
        <header class="">
            
            <Sidebar {...{ isOpen, setIsOpen }} />

          <nav class="navbar fixed-top navbar-expand-lg scrolling-navbar double-nav col-12">
          
          <div class="float-left"
          onClick={() => setIsOpen(true)}
          >
        <a data-activates="slide-out" class="button-collapse"><i class="fa fa-bars black-text"></i></a>
      </div>
                    
              <ul class="nav navbar-nav nav-flex-icons ml-auto">

              <li class="nav-item dropdown notifications-nav">
                  <a class="nav-link dropdown-toggle waves-effect" id="userDropdown" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                   <i class="fa fa-user"></i>
                    <span class="d-md-inline-block">My Account</span>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
                  <a class="dropdown-item" href="Profile">Profile</a>
                  <a class="dropdown-item" href="ChangePassword">Change Password</a>
                  <a class="dropdown-item" href="">Change Username</a>
                  </div>
                </li>

                <li class="nav-item dropdown notifications-nav">
                  <a class="nav-link dropdown-toggle waves-effect" id="userDropdown" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                   <i class="fa fa-sign-out red-text"></i>
                    <span class="d-md-inline-block">Sign Out</span>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
                  <a class="dropdown-item" href="/">Log Out</a>
                  </div>
                </li>
      
              </ul>
                   
          </nav>
      
        </header>
    
    
    );
    
    }
    
    export default Header;