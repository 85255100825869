//import logo from './logo.svg'
import './App.css'
import Layout from './pages/Layout'
import Missing from './pages/Missing'
import Welcome from './pages/Home'
import Dashboard from './pages/Dashboard'
import RegisterUsers from './pages/RegisterUsers'
import NewMessage from './pages/NewMessage'
import Inbox from './pages/Inbox'
import Outbox from './pages/Outbox'
import ViewUsers from './pages/ViewUsers'
import ViewMessages from './pages/ViewMessages'
import Profile from './pages/Profile'
import ChangePassword from './pages/ChangePassword'
import ForgotPassword from './pages/ForgotPassword'

/*import About from './pages/About'
import Contact from './pages/Contact'
import Footer from './pages/Footer'
import Header from './pages/Header'
import RegisterLeague from './pages/RegisterLeague'
import RegisterClub from './pages/RegisterClub'
import RegisterPlayer from './pages/RegisterPlayer'*/

import { Routes, Route } from 'react-router-dom'

function App () {
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        {/* Public Routes*/}
        {/*<Route path='About' element={<About />} />
        <Route path='Contact' element={<Contact />} />
        <Route path='Footer' element={<Footer />} />
        <Route path='Header' element={<Header />} />
        <Route path='RegisterLeague' element={<RegisterLeague />} />
        <Route path='RegisterClub' element={<RegisterClub />} />
        <Route path='RegisterPlayer' element={<RegisterPlayer />} />*/}

        {/* We want To Protect These Routes*/}
        <Route path='/' element={<Welcome />} />        
        <Route path='Dashboard' element={<Dashboard />} />
        <Route path='RegisterUsers' element={<RegisterUsers />} />
        <Route path='NewMessage' element={<NewMessage />} />
        <Route path='Inbox' element={<Inbox />} />
        <Route path='Outbox' element={<Outbox />} />
        <Route path='ViewUsers' element={<ViewUsers />} />
        <Route path='ViewMessages' element={<ViewMessages />} />
        <Route path='Profile' element={<Profile />} />
        <Route path='ChangePassword' element={<ChangePassword />} />
        <Route path='ForgotPassword' element={<ForgotPassword />} />
        {/* catch all*/}
        <Route path='*' element={<Missing />} />
      </Route>
    </Routes>
  )
}

export default App
