import React, {useState, useEffect} from 'react';
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBInputGroup,
  MDBCheckbox,
  MDBIcon
}
from 'mdb-react-ui-kit';
import Header from './Header';
import Axios from 'axios'

import {
  faCheck,
  faTimes,
  faInfoCircle,
  faRefresh
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function ChangePassword() {

  const server_app_api_php = window.SERVER_APP_API_PHP

  const PHONE_REGEX =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
const EMAIL_REGEX = /^[^@]+@[^@]+\.[^@]{2,4}$/

let [OTP, setOTP] = useState('')
let [validOTP, setValidOTP] = useState(false)
let [oldPassword, setOldPassword] = useState('')
let [validOldPassword, setValidOldPassword] = useState(false)
let [email, setEmail]= useState('')
let [validEmail, setValidEmail] = useState(false)
let [newPassword, setNewPassword] = useState('')
let [repeatPassword, setRepeatPassword] = useState('')
let [validRepeatPassword, setValidRepeatPassword] = useState(false)
let [repeatPasswordFocus, setRepeatPasswordFocus] = useState(false)
let [sendEmailButton, setSendEmailButton] = useState(true)
let [OTPButton, setOTPButton] = useState(false)
let [oldPasswordButton, setOldPasswordButton] = useState(false)
let [handleSubmitButton, setHandleSubmitButton] = useState(false)

const [showPasswordNew, setShowPasswordNew] = useState(false);
const handleShowPasswordNew = () => setShowPasswordNew(!showPasswordNew);

const [changePasswordNew, setChangePasswordNew] = useState(true);
const changeIconNew = changePasswordNew === true ? false : true;
var fontSizeNew = '200%';
var colorNew = 'red';
var eyeIconNew = 'fa fa-eye';
if(changeIconNew === true){
    fontSizeNew = '200%';
    colorNew = 'black';
  eyeIconNew = 'fa fa-eye-slash';
}



const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);
const handleShowPasswordRepeat = () => setShowPasswordRepeat(!showPasswordRepeat);

const [changePasswordRepeat, setChangePasswordRepeat] = useState(true);
const changeIconRepeat = changePasswordRepeat === true ? false : true;
var fontSizeRepeat= '200%';
var colorRepeat = 'red';
var eyeIconRepeat = 'fa fa-eye';
if(changeIconRepeat === true){
    fontSizeRepeat = '200%';
    colorRepeat = 'black';
  eyeIconRepeat = 'fa fa-eye-slash';
}

let ID = localStorage.getItem('userID')
ID = JSON.parse(ID)

 useEffect(() => {
    const result = EMAIL_REGEX.test(email)
    setValidEmail(result)
  }, [email])


  useEffect(() => {
    if(newPassword === repeatPassword){
    setValidRepeatPassword(true)
  }
  else{
    setValidRepeatPassword(false)
  }
  }, [repeatPassword, newPassword])


  const handleSubmitEmail = async e => {
    //e.preventDefault()
    //alert(ID)
    var formData = new FormData(this)
    formData.append('apiType', 'changePasswordGenerateOTP')
    formData.append('email', email)
    formData.append('id', ID)
   Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(function (response) {
        //handle success
        
        if(response.data === "Email Is Correct"){
            alert("An OTP Has Been Sent To Your Email.")
            setOTPButton(true)
            setSendEmailButton(false)
        }
        else{
            alert(response.data)
            setOTPButton(false)
            setSendEmailButton(true)
        }
      })
      .catch(function (response) {
        //handle error
        alert("Error: Check Your Internet Connection")
      })
  }

  const handleSubmitOTP = async e => {
    var formData = new FormData(this)
    formData.append('apiType', 'changePasswordGenerateComfirmOTP')
    formData.append('email', email)
    formData.append('OTP', OTP)
    formData.append('id', ID)
   Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(function (response) {
        //handle success
        
        if(response.data === "Correct OTP"){
            alert("Your OTP Is Valid, Now Enter The Old Password To Continue")
            setValidOTP(true)
            setOldPasswordButton(true)
            setOTPButton(false)
            setSendEmailButton(false)
        }
        else {
            alert(response.data)
            setValidOTP(false)
            setOldPasswordButton(false)
            setOTPButton(true)
            setSendEmailButton(false)
        } 
      })
      .catch(function (response) {
        //handle error
        alert("Error: Check Your Internet Connection")
      })
}

  const handleSubmitOldPassword = async e => {
    var formData = new FormData(this)
    formData.append('apiType', 'changePasswordComfirmOldPassword')
    formData.append('email', email)
    formData.append('password', oldPassword)
    formData.append('id', ID)
   Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(function (response) {
        //handle success
        
        if(response.data === "Old Password Is Correct"){
            alert("Old Password Correct. Now Your Can Create A password Which Is Easy To Remember")
            setValidOldPassword(true)
            setHandleSubmitButton(true)
            setOldPasswordButton(false)
            setSendEmailButton(false)
        }
        else {
            alert(response.data)
            setValidOldPassword(false)
            setHandleSubmitButton(false)
            setValidOTP(false)
            setOldPasswordButton(true)
            setOTPButton(false)
            setSendEmailButton(false)
        }    
      })
      .catch(function (response) {
        //handle error
        alert("Error: Check Your Internet Connection")
      })
}

const handleSubmit = async e => {
    //e.preventDefault()
    var formData = new FormData(this)
    formData.append('apiType', 'ChangePassword')
    formData.append('email', email)
    formData.append('password', newPassword)
    formData.append('id', ID)
   Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(function (response) {
        //handle success
        alert(response.data)
        setNewPassword('')
        setRepeatPassword('')        
      })
      .catch(function (response) {
        //handle error
        alert("Error: Check Your Internet Connection")
      })
   
  }

const handleSubmitReset = async e => {
    setValidOTP(false)
    setValidOldPassword(false)
    setValidEmail(false)
    setValidRepeatPassword(false)
    setRepeatPasswordFocus(false)
    setSendEmailButton(true)
    setOTPButton(false)
    setOldPasswordButton(false)
    setHandleSubmitButton(false)
    setEmail('')
    setOTP('')
    setOldPassword('')
    setNewPassword('')
    setRepeatPassword('') 
}

  return (
    <MDBContainer fluid className='p-4 background-radial-gradient overflow-hidden'>

 <Header />

      <MDBRow>

        <div className='col-lg-5 col-md-12 text-center text-md-start d-flex flex-column justify-content-center'>

          <h3 className="my-5 display-6 fw-bold ls-tight px-1" style={{color: 'hsl(218, 81%, 95%)'}}>            
            <span style={{color: 'red'}}>CHANGE PASSWORD</span>
          </h3>

          <p className='px-3 text-left' style={{color: 'blue'}}>
          FIRST ENTER EMAIL
          </p>
          <p className='px-3 text-left' style={{color: 'blue'}}>
          THEN ENTER OTP SENT TO YOUR EMAIL
          </p>
          <p className='px-3 text-left' style={{color: 'blue'}}>
          AFTER THAT, ENTER OLD PASSWORD IF OTP IS CORRECT
          </p>
          <p className='px-3 text-left' style={{color: 'blue'}}>
          IF OLD PASSWORD IS CORRECT, THEN CREATE NEW PASSWORD
          </p>

        </div>

        <div className='col-lg-7 col-md-12'>

          <MDBCard className='my-5 bg-glass'>
          <span type="button" onClick={handleSubmitReset}
           class="ml-5 mt-3 mb-3 text-left green-text" title="Reset The Process"> <FontAwesomeIcon icon={faRefresh} /> </span>
            <MDBCardBody className='p-5'>
                
<MDBRow>
          <div class="col-lg-12 col-md-12" >
            <div class="md-form md-outline">
                <input type="text" class="form-control"  onChange={e => setEmail(e.target.value)} value={email} 
                 />
                <label for="f2" class="">Email</label>
                </div>
                </div>

                <div class="col-lg-12 col-md-12" hidden = {!OTPButton}>
                <div class="md-form md-outline">
                <input type="text" class="form-control"   onChange={e => setOTP(e.target.value)} value={OTP}
                />
                <label for="f2" class="">OTP</label>
                </div>
                </div>
                
                <div class="col-lg-12 col-md-12" hidden = {!oldPasswordButton}>
                <div class="md-form md-outline">
                <input type="text" class="form-control"   onChange={e => setOldPassword(e.target.value)} value={oldPassword}
                />
                <label for="f2" class="">Old Password</label>
                </div>
                </div>
                
                <div class="col-lg-12 col-md-12 text-left" hidden = {!validOldPassword}>
        <label >New Password</label>                     
      <MDBInputGroup className='mb-3' textBefore={<i class='fa fa-lock prefix red-text'></i>} textAfter= {showPasswordNew ? <MDBIcon icon='eye-slash' style={{fontSize:'100%', color:'black'}} type='button' onClick={handleShowPasswordNew}/> : <MDBIcon icon='eye' type='button' onClick={handleShowPasswordNew} style={{fontSize:'100%', color:'red'}}/> }>
        <input type={showPasswordNew ? "text" : "password"} className='form-control' onChange={e => setNewPassword(e.target.value)} value={newPassword} />
      </MDBInputGroup>

                </div>
                
                <div class="col-lg-12 col-md-12 text-left" hidden = {!validOldPassword}>

                <label>Repeated Password</label>                     
      <MDBInputGroup className='mb-3' textBefore={<i class='fa fa-lock prefix red-text'></i>} textAfter= {showPasswordRepeat ? <MDBIcon icon='eye-slash' style={{fontSize:'100%', color:'black'}} type='button' onClick={handleShowPasswordRepeat}/> : <MDBIcon icon='eye' type='button' onClick={handleShowPasswordRepeat} style={{fontSize:'100%', color:'red'}}/> }>
        <input type={showPasswordRepeat ? "text" : "password"} className='form-control' onChange={e => setRepeatPassword(e.target.value)} value={repeatPassword} 
        onFocus={() => setRepeatPasswordFocus(true)}
        onBlur={() => setRepeatPasswordFocus(false)}
      />      
      </MDBInputGroup>

                <p
    className='red-text'
        hidden={
            repeatPasswordFocus === false || validRepeatPassword === true
        }
      >
        <FontAwesomeIcon icon={faInfoCircle} />
        Password Should Be The Same As The New Password
      </p>
     

                </div>

              </MDBRow>
              
<div className='row' hidden = {!sendEmailButton}>
              <button className='col-12 btn btn-primary' 
              onClick={handleSubmitEmail} disabled={
                !validEmail              
                }>
                Submit Email
                </button>
                </div>

                <div className='row' hidden = {!OTPButton}>
              <button className='col-12 btn btn-primary' 
              onClick={handleSubmitOTP} disabled={
                OTP === ''               
                }>
                Send OTP
                </button>
                </div>

                <div className='row' hidden = {!oldPasswordButton}>
              <button className='col-12 btn btn-primary' 
              onClick={handleSubmitOldPassword} disabled={
                oldPassword === ''              
                }>
                Submit Old Password
                </button>
                </div>

                <div className='row' hidden = {!handleSubmitButton}>
              <button className='col-12 btn btn-primary' 
              onClick={handleSubmit} disabled={
                !validRepeatPassword                
                }>
                    Change Password            
                </button>
                </div>

             </MDBCardBody>
          </MDBCard>

        </div>

      </MDBRow>

    </MDBContainer>
  );
}

export default ChangePassword;