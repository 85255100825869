import React, {useState, useEffect} from 'react';
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBCheckbox,
  MDBIcon,
  MDBFile,
  MDBTable, MDBTableHead, MDBTableBody,
  
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody
}
from 'mdb-react-ui-kit';
import { MDBDataTable } from "mdbreact";
import Header from './Header';
import Axios from 'axios'

import {
  faCheck,
  faTimes,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Inbox() {

    let source = localStorage.getItem('userID')
    source = JSON.parse(source)


  const server_app_api_php = window.SERVER_APP_API_PHP

  const[destination, setDestination]= useState('')
	const[comments, setComments]= useState('')
  const [message, setMessage] = useState()

  let [handleSubmitBtn, setHandleSubmitBtn] = useState('')

  const [rows, setDBtableView] = useState([])
   // const source = 1;

  let [destinationDB, setdestinationDB] = useState('')
  let [originalMessagesID, setOriginalMessagesID] = useState('')
  let [messagesID, setMessagesID] = useState('')
  const [modalReplies, setModalReplies] = useState([])

  const [selectedRows, setSelectedRows] = useState([]);
  //const [selectedRows, setSelectedRows] = useState([]);

   // Function to generate row attributes
   const generateRowAttr = row => {
    return {
      className: row.read_status.toLowerCase() === 'unread' ? 'unread-row' : ''
    };
  };
  
const handleCheckboxChange = (rowId) => {
  const selectedIndex = selectedRows.indexOf(rowId);
  let newSelectedRows = [...selectedRows];
  if (selectedIndex === -1) {
    newSelectedRows = [...newSelectedRows, rowId];
    //alert(newSelectedRows)
  } else {
    newSelectedRows.splice(selectedIndex, 1);
  }

  setSelectedRows(newSelectedRows);
};

const handleSelectAll = (event) => {
  if (event.target.checked) {
    const allRowIds = rows.map(row => row.selectedID);
    setSelectedRows(allRowIds);
    alert(allRowIds)
  } else {
    setSelectedRows([]);
  }
};


const handleModalReply = modalReply => {
  //alert(modalReply)
  localStorage.setItem('inboxReply', JSON.stringify(modalReply))
  setHandleSubmitBtn('Loading');
  var formData = new FormData(this)  
  formData.append('apiType', 'getReplyMessages')
  formData.append('messagesID', modalReply)
  Axios({
    method: 'post',
    url: `${server_app_api_php}/reactphp/index.php`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
    .then(function (response) {
      //handle success
      setHandleSubmitBtn('');
      //alert(response.data)
      setModalReplies(response.data)
    })
    .catch(function (response) {
      //handle error
      alert("Error: Failed To Extract Replies. check Your Internet Connection")
    })

    //setHandleSubmitBtn('Loading');
var formData = new FormData(this)
formData.append('apiType', 'sendReplyMessageGetDestination')
formData.append('id', modalReply)
Axios({
  method: 'post',
  url: `${server_app_api_php}/reactphp/index.php`,
  data: formData,
  headers: { 'Content-Type': 'multipart/form-data' }
})
  .then(function (response) {
    setdestinationDB(response.data.destination)
    setOriginalMessagesID(response.data.originalMessagesID)
  })
}

const handleModalDestination = modalDestination => {
  //alert(modalDestination)
setHandleSubmitBtn('Loading');
var formData = new FormData(this)

formData.append('apiType', 'sendReplyMessageGetDestination')
formData.append('id', modalDestination)
Axios({
  method: 'post',
  url: `${server_app_api_php}/reactphp/index.php`,
  data: formData,
  headers: { 'Content-Type': 'multipart/form-data' }
})
  .then(function (response) {
    //handle success
    setHandleSubmitBtn('');
    //alert(response.data)
    setdestinationDB(response.data.destination)
    //setOriginalMessagesID(response.data.messagesID)
    setOriginalMessagesID(response.data.originalMessagesID)
    //localStorage.setItem('inboxReplyDestination', JSON.stringify(response.data.destination))
    //alert(response.data.originalMessagesID)
  })
  .catch(function (response) {
    //handle error
    alert("Error: Failed To Extract Destination ID. check Your Internet Connection")
  })
  //setdestinationDB(modalDestination)
}


   
   const saveMessage = e => {
    setMessage(e.target.files[0])
  }

const handleSubmit = async e => {
//e.preventDefault()
setHandleSubmitBtn('Loading');
var formData = new FormData(this)

formData.append('apiType', 'sendReplyMessage')
formData.append('destination', destination)
formData.append('comments', comments)
formData.append('message', message)
formData.append('source', source)
formData.append('originalMessagesID', originalMessagesID)
Axios({
  method: 'post',
  url: `${server_app_api_php}/reactphp/index.php`,
  data: formData,
  headers: { 'Content-Type': 'multipart/form-data' }
})
  .then(function (response) {
    //handle success
    setHandleSubmitBtn('');
    alert(response.data)

    setDestination('')
    setComments('')
    setMessage('')
    document.getElementById('document').value = '';
  })
  .catch(function (response) {
    //handle error
    alert("Error: Check Your Internet Connection")
  })

}
  
	
    useEffect(() => {
        setHandleSubmitBtn('Loading');
    var formData = new FormData(this)

    formData.append('apiType', 'viewInbox')
    formData.append('id', source)
   Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(function (response) {
        //handle success
       //alert(response.data)
        setHandleSubmitBtn('');
       setDBtableView(response.data)
      })
      .catch(function (response) {
        //handle error
        alert("Error: Check Your Internet Connection")
      })
   
    }, [])

  
  let dataReplies = {
    columns: [
     
      {
          label: "SN",
          field: "sn"
        },
        {
          label: "From",
          field: "positionSource",
          sort: "asc",
          width: 270
        },
        {
          label: "From(Name)",
          field: "nameSource",
          sort: "asc",
          width: 270
        },
        {
          label: "comments",
          field: "comments",
          sort: "asc",
          width: 150
        },
        {
          label: "To",
          field: "positionDestination",
          sort: "asc",
          width: 270
        },
        {
          label: "To(Name)",
          field: "nameDestination",
          sort: "asc",
          width: 270
        },
        {
          label: "Date",
          field: "dateSent",
          sort: "asc",
          width: 270
        },
        {
          label: "Time",
          field: "timeSent",
          sort: "asc",
          width: 270
        },
    {
      label: "Action",
      field: "messagesID",
      sort: "asc",
      width: 100
    }
  ],
    rows: modalReplies  
  };

  modalReplies.map( (item,index) => {
    if(item.counter != '0'){
    item.sn = (
  <div>
    {index +1}
  </div>
    );
  }
  });
  modalReplies.map( (item,) => {
    if(item.counter != '0' && item.item !=""){
    item.messagesID = (
<div>   
                                  <span 
                                  onClick={() => {
                                    downloadDoc(item.item)
                                  }}
                                  type='button'
                                  class='fa fa-download green-text'
                                  title='Download Document'
                                ></span>
</div>
    );
  
 }

 else  if(item.counter != '0' && item.item ===""){
  item.messagesID = (
<div> </div>
  );
 }
  
  });

  let data = {
    columns: [
      {
        label: <input type="checkbox" onChange={handleSelectAll} />,
        field: "checkbox",
        width: 50
      },
      {
          label: "SN",
          field: "sn"
        },
        {
          label: "From",
          field: "source",
          sort: "asc",
          width: 270
        },
        /*{
          label: "From(Name)",
          field: "nameSource",
          sort: "asc",
          width: 270
        },*/
        {
          label: "comments",
          field: "comments",
          sort: "asc",
          width: 150
        },
        {
          label: "Date",
          field: "dateSent",
          sort: "asc",
          width: 270
        },
        {
          label: "Time",
          field: "timeSent",
          sort: "asc",
          width: 270
        },
    {
      label: "Action",
      field: "messagesID",
      sort: "asc",
      width: 100
    }
  ],
  //rows: rows  
  rows: rows.map(row => ({
    checkbox: <input type="checkbox" checked={selectedRows.includes(row.selectedID)} onChange={() => handleCheckboxChange(row.selectedID)} />,
    sn: row.sn,
    source: row.source,
    comments: row.comments,
    dateSent: row.dateSent,
    timeSent: row.timeSent,
    messagesID: row.messagesID,
    //className: row.read_status.toLowerCase() === "unread" ? "unread-row" : "" // Apply 'unread-row' class if read_status is 'unread'
    className: row.read_status === "unread" ? "unread-row" : ""
  })),
  // Apply row attributes dynamically
  rowAttr: generateRowAttr
};

// Define CSS for styling

// Assuming you have a way to inject styles into your page,
// you can add the styles like this
//injectStyles(styles);

rows.map( (item,index) => {
  if(item.counter != '0'){
  item.sn = (
<div>
  {index +1}
</div>
  );
}
});

  rows.map( (item,) => {
    if(item.counter != '0' && item.item !=""){
    item.messagesID = (
<div>   
                                  <span 
                                  onClick={() => {
                                    downloadDoc(item.item)
                                  }}
                                  type='button'
                                  class='fa fa-download green-text'
                                  title='Download Document'
                                ></span>
                                <span class="fa fa-reply ml-5"
                                onClick={() => {
                                  handleModalDestination(item.originalMessagesID)
                                }}
                                data-toggle='modal'
                                data-target='#modal'
                                ></span>
                                 {
                     item.reply === "Yes" ? (
<>
<span class="fa fa-plus ml-5"
                              onClick={() => {
                                handleModalReply(item.replyID)
                              }}
                              data-toggle='modal'
                              data-target='#modalReply'
                              title='View Replies'
                              ></span>
</>
                     ) : (<></>)
                    } 
                                
</div>
    );
 }
 else   if(item.counter != '0' && item.item !="" && item.reply === "Yes"){
  item.messagesID = (
<div>   
                                  <span 
                                  onClick={() => {
                                    downloadDoc(item.item)
                                  }}
                                  type='button'
                                  class='fa fa-download green-text'
                                  title='Download Document'
                                ></span>
                                <span class="fa fa-reply ml-5"
                                onClick={() => {
                                  handleModalDestination(item.originalMessagesID)
                                }}
                                data-toggle='modal'
                                data-target='#modal'
                                ></span>
                                 <span class="fa fa-plus ml-5"
                                onClick={() => {
                                  handleModalReply(item.replyID)
                                }}
                                data-toggle='modal'
                                data-target='#modal'
                                title='View Replies'
                                ></span>
                                 {
                     item.reply === "Yes" ? (
<>
<span class="fa fa-plus ml-5"
                              onClick={() => {
                                handleModalReply(item.replyID)
                              }}
                              data-toggle='modal'
                              data-target='#modalReply'
                              title='View Replies'
                              ></span>
</>
                     ) : (<></>)
                    } 
</div>
  );
}
 else   if(item.counter != '0' && item.item ===""){
  item.messagesID = (
<div>
 
                              <span class="fa fa-reply"
                              onClick={() => {
                                handleModalDestination(item.originalMessagesID)
                              }}
                              data-toggle='modal'
                              data-target='#modal'
                              ></span>
                    {
                     item.reply === "Yes" ? (
<>
<span class="fa fa-plus ml-5"
                              onClick={() => {
                                handleModalReply(item.replyID)
                              }}
                              data-toggle='modal'
                              data-target='#modalReply'
                              title='View Replies'
                              ></span>
</>
                     ) : (<></>)
                    }                              
</div>
  );    
}
  });
  
  const downloadDoc = file => {
    window.open(`${server_app_api_php}/reactphp/messages docs/${file}`)
}
 
  return (
    <MDBContainer fluid className='p-4 background-radial-gradient overflow-hidden'>

 <Header />


      <MDBRow>

      <div hidden={handleSubmitBtn ===''}>
    <div id='overlayer'></div>
    <div class="loader d-flex justify-content-center">
  <div class="spinner-grow text-primary" style={{width: '10rem', height: '10rem'}} role="status">
      <span class='visually-hidden'>Loading...</span>
    </div>
  </div>
  </div>



  <MDBModal tabIndex='-1' id='modalReply'>
        <MDBModalDialog size='xl'>
          <MDBModalContent>
            <MDBModalHeader class='modal-header ocean blue-gradient white-text '>
              <MDBModalTitle><h4 class=''>Replies</h4></MDBModalTitle>
              <button
                              type='button'
                              class='close waves-effect waves-light white-text'
                              data-dismiss='modal'
                              aria-label='Close'
                            >
                              <span aria-hidden='true'>&times;</span>
                            </button>
            </MDBModalHeader>
            <MDBModalBody>         
            <MDBDataTable striped bordered hover small data={dataReplies} selectRows={true}  />

            <MDBRow>

<MDBCol md='12' className='position-relative'>

  <div id="radius-shape-1" className="position-absolute rounded-circle shadow-5-strong"></div>
  <div id="radius-shape-2" className="position-absolute shadow-5-strong"></div>

  <MDBCard className='my-0 bg-glass'>
    <MDBCardBody className='p-5'>

    <MDBRow>
    <MDBCol col='6'>
        
    <div class="md-form md-outline">
    <label for="f2" class="">Attach File</label> <br /> <br />
    <input type="file" class="form-control" id="document" onChange={saveMessage}  />
        
        </div>
        </MDBCol>

          <MDBCol col='6'>
          <div class="md-form md-outline">
          <label for="f2" class="">Destination <span class="fa fa-star red-text"></span></label> <br /> <br />
          <select
                      class='custom-select d-block w-100'        
                      autoComplete='off'
                      onChange={e => setDestination(e.target.value)}
                      value={destination}
                      required                            
                    >
                   <option value=''>Choose Destination....</option>
                   <option>{destinationDB}</option>                 
                    </select>
          </div>
          </MDBCol>
        </MDBRow>


<MDBRow>
  <MDBCol col='4'>
    <div class="md-form md-outline">
        <textarea class="form-control"  onChange={e => setComments(e.target.value)} value={comments}></textarea>
        <label for="f2" class="">Brief Comments <span class="fa fa-star red-text"></span></label>
        </div>
        </MDBCol>

        </MDBRow>
     
      <MDBBtn className='w-100 mb-0' size='md'
      onClick={handleSubmit} disabled={
        comments === '' || destination === ''                   
        }>
        Send
        </MDBBtn>

        <MDBRow>
        <MDBCol class="12">
        <span class="fa fa-star red-text 3x text-left mt-5"></span> -: Should Not Be Empty
        </MDBCol>
        </MDBRow>

     </MDBCardBody>
  </MDBCard>

</MDBCol>

</MDBRow>

  </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>



  <MDBModal tabIndex='-1' id='modal'>
        <MDBModalDialog size='lg'>
          <MDBModalContent>
            <MDBModalHeader class='modal-header ocean blue-gradient white-text '>
              <MDBModalTitle><h4 class=''>Reply </h4></MDBModalTitle>
              <button
                              type='button'
                              class='close waves-effect waves-light white-text'
                              data-dismiss='modal'
                              aria-label='Close'
                            >
                              <span aria-hidden='true'>&times;</span>
                            </button>
            </MDBModalHeader>
            <MDBModalBody>

            <MDBRow>

<MDBCol md='12' className='position-relative'>

  <div id="radius-shape-1" className="position-absolute rounded-circle shadow-5-strong"></div>
  <div id="radius-shape-2" className="position-absolute shadow-5-strong"></div>

  <MDBCard className='my-0 bg-glass'>
    <MDBCardBody className='p-5'>

    <MDBRow>
    <MDBCol col='6'>
        
    <div class="md-form md-outline">
    <label for="f2" class="">Attach File</label> <br /> <br />
    <input type="file" class="form-control" id="document" onChange={saveMessage}  />
        
        </div>
        </MDBCol>

          <MDBCol col='6'>
          <div class="md-form md-outline">
          <label for="f2" class="">Destination <span class="fa fa-star red-text"></span></label> <br /> <br />
          <select
                      class='custom-select d-block w-100'        
                      autoComplete='off'
                      onChange={e => setDestination(e.target.value)}
                      value={destination}
                      required                            
                    >
                   <option value=''>Choose Destination....</option>
                   <option>{destinationDB}</option>                 
                    </select>
          </div>
          </MDBCol>
        </MDBRow>


<MDBRow>
  <MDBCol col='4'>
    <div class="md-form md-outline">
        <textarea class="form-control"  onChange={e => setComments(e.target.value)} value={comments}></textarea>
        <label for="f2" class="">Brief Comments <span class="fa fa-star red-text"></span></label>
        </div>
        </MDBCol>

        </MDBRow>
     
      <MDBBtn className='w-100 mb-0' size='md'
      onClick={handleSubmit} disabled={
        comments === '' || destination === ''                   
        }>
        Send
        </MDBBtn>

        <MDBRow>
        <MDBCol class="12">
        <span class="fa fa-star red-text 3x text-left mt-5"></span> -: Should Not Be Empty
        </MDBCol>
        </MDBRow>

     </MDBCardBody>
  </MDBCard>

</MDBCol>

</MDBRow>
  
  </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>


        <MDBCol md='12' className='position-relative text-center text-md-start d-flex flex-column justify-content-center'>

          <MDBCard className='my-5 bg-glass'>
            <MDBCardBody className='p-5'>
            
            <div className='row'>

<div className='col-lg-4 col-md-12'>
<span className='fas fa-envelope' type ='button' title='Mark as Unread'></span>
</div>

<div className='col-lg-4 col-md-12'>
<span className='fas fa-envelope-open-text grey-text' type ='button' title='Mark as read'></span>
</div>

<div className='col-lg-4 col-md-12'>
<span className='fas fa-trash red-text' type ='button' title='Delete'></span>
</div>

 </div>
<hr />
            <MDBDataTable bordered hover small data={data} selectRows={true}  />

             </MDBCardBody>
          </MDBCard>

        </MDBCol>

      </MDBRow>

    </MDBContainer>
  );
}

export default Inbox;

const styles = `
  .unread-row {
    backgroundColor: red;
  }
`;