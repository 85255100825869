import { Box, Drawer, IconButton, styled, Typography } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Sidebar = ({ isOpen, setIsOpen }) => {

  let loggedInUserID = localStorage.getItem('userID')
  loggedInUserID = JSON.parse(loggedInUserID)
  
  let loggedInUserPosition = localStorage.getItem('userPosition')
  loggedInUserPosition = JSON.parse(loggedInUserPosition)
  //alert(loggedInUserPosition)

  return (
    <>
    
    <Drawer variant="persistent" hideBackdrop={true} open={isOpen}>
      <DrawerHeader>
        <Typography>
        <i class="fas fa-tachometer-alt"></i>
         <a href="./Dashboard">DASHBOARD</a> 
        </Typography>
        <IconButton onClick={() => setIsOpen(false)}>
          <ChevronLeft fontSize="large" />
        </IconButton>
      </DrawerHeader>
      <Box sx={{ width: 240, p: 3 }}>
   

    <div id="slide-out" class="sn-bg-4 fixed">

    <ul class="nav navbar-nav nav-flex-icons ml-auto mdb-color white text-left">

<li hidden={loggedInUserPosition !='Chief Of Operations' && loggedInUserPosition !='Admin'} class="nav-item dropdown notifications-nav">
    <a class="nav-link dropdown-toggle waves-effect" id="navbarDropdownMenuLink" data-toggle="dropdown"
      aria-haspopup="true" aria-expanded="false">
     <i class="fa fa-cog"></i>
      <span class="d-md-inline-block">Settings</span>
    </a>
    <div class="dropdown-menu dropdown-primary" aria-labelledby="navbarDropdownMenuLink">
      <a class="dropdown-item" href="RegisterUsers">
        <i class="fa fa-edit mr-2" aria-hidden="true"></i>
        <span>Register Users</span>
      </a>
    </div>
  </li>

  
<li hidden={loggedInUserPosition !='Vice Chief Of Operations' && loggedInUserPosition !='Chief Of Operations' && loggedInUserPosition !='Admin'} class="nav-item dropdown notifications-nav">
    <a class="nav-link dropdown-toggle waves-effect" id="navbarDropdownMenuLink" data-toggle="dropdown"
      aria-haspopup="true" aria-expanded="false">
     <i class="fa fa-eye"></i>
      <span class="d-md-inline-block">Reports</span>
    </a>
    <div class="dropdown-menu dropdown-primary" aria-labelledby="navbarDropdownMenuLink">
      <a class="dropdown-item" href="ViewUsers">
        <i class="fa fa-eye mr-2" aria-hidden="true"></i>
        <span>View Users</span>
      </a>
      <a class="dropdown-item" href="ViewMessages">
        <i class="fa fa-envelope mr-2" aria-hidden="true"></i>
        <span>View Messages</span>
      </a>
      
    </div>
  </li>

  
  <li class="nav-item dropdown notifications-nav">
    <a class="nav-link dropdown-toggle waves-effect" id="navbarDropdownMenuLink" data-toggle="dropdown"
      aria-haspopup="true" aria-expanded="false">
     <i class="fa fa-envelope"></i>
      <span class="d-md-inline-block">Messages</span>
    </a>
    <div class="dropdown-menu dropdown-primary" aria-labelledby="navbarDropdownMenuLink">
      <a class="dropdown-item" href="NewMessage">
        <i class="fa fa-edit mr-2" aria-hidden="true"></i>
        <span>New Message</span>
      </a>
      <a class="dropdown-item" href="Inbox">
        <i class="fa fa-envelope mr-2" aria-hidden="true"></i>
        <span>Inbox</span>
      </a>
      <a class="dropdown-item" href="Outbox">
        <i class="fa fa-envelope-open mr-2" aria-hidden="true"></i>
        <span>Outbox</span>
      </a>
    </div>
  </li>

  <li class="nav-item notifications-nav red-text">
  
  <a class="red-text" href="/">
  <i class="fa fa-sign-out mr-2" aria-hidden="true"></i>
    Exit
    </a>
  </li>

</ul>
   
    <div class="sidenav-bg mask-strong"></div>
  </div>

  </Box>
    </Drawer>

 </>
  );
};

export default Sidebar;
