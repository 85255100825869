import React, {useState, useEffect} from 'react';
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBCheckbox,
  MDBIcon,
  MDBFile
}
from 'mdb-react-ui-kit';
import Header from './Header';
import Axios from 'axios'

import {
  faCheck,
  faTimes,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function NewMessage() {
    let source = localStorage.getItem('userID')
    source = JSON.parse(source)

  const server_app_api_php = window.SERVER_APP_API_PHP

 

   const[destination, setDestination]= useState('')
	const[comments, setComments]= useState('')
    const [message, setMessage] = useState()
    const [messageStatus, setMessageStatus] = useState()

    useEffect( () =>{
      setMessageStatus('')
    }, [])
   
   // const source = 1;

    const saveMessage = e => {
        setMessage(e.target.files[0])
        setMessageStatus('Filled')
      }
	
  const handleSubmit = async e => {
    //e.preventDefault()
    var formData = new FormData(this)

    formData.append('apiType', 'sendNewMessage')
    formData.append('destination', destination)
    formData.append('comments', comments)
    formData.append('message', message)
    formData.append('source', source)
   Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(function (response) {
        //handle success
        alert(response.data)

        setDestination('')
        setComments('')
        setMessage('')
        setMessageStatus('')
        document.getElementById('document').value = '';
      })
      .catch(function (response) {
        //handle error
        alert("Error: Check Your Internet Connection")
      })
   
  }


  return (
    <MDBContainer fluid className='p-4 background-radial-gradient overflow-hidden'>

 <Header />

      <MDBRow>

        <MDBCol md='3' className='text-center text-md-start d-flex flex-column justify-content-center'>

          <h3 className="my-5 display-6 fw-bold ls-tight px-1" style={{color: 'hsl(218, 81%, 95%)'}}>            
            <span style={{color: 'green'}}>CREATE MESSAGE</span>
          </h3>

          <p className='px-3 MDBColor primary' >
          Initiate your messsage here.
          </p>

        </MDBCol>

        <MDBCol md='9' className='position-relative'>

          <div id="radius-shape-1" className="position-absolute rounded-circle shadow-5-strong"></div>
          <div id="radius-shape-2" className="position-absolute shadow-5-strong"></div>

          <MDBCard className='my-5 bg-glass'>
            <MDBCardBody className='p-5'>
        
            <MDBRow>
            <MDBCol col='6'>
                
            <div class="md-form md-outline">
            <label for="f2" class="">Attach File </label> <br /> <br />
            <input type="file" class="form-control" id="document" onChange={saveMessage}  />
                
                </div>
                </MDBCol>
  
                  <MDBCol col='6'>
                  <div class="md-form md-outline">
                  <label for="f2" class="">Destination <span class="fa fa-star red-text"></span> </label> <br /> <br />
                  <select
                              class='custom-select d-block w-100'        
                              autoComplete='off'
                              onChange={e => setDestination(e.target.value)}
                              value={destination}
                              required                            
                            >
                           <option value=''>Choose Destination....</option>
                           <option>Chief Of Operations</option>
                         <option>Vice Chief Of Operations</option>
                         <option>DATS</option>
                         <option>Director Ground Defence</option>
                         <option>Director Air Defence</option>
                         <option>D UAVs</option>
                         <option>Director OPS Standards</option>
                         <option>DUNO</option>
                         <option>CATCO (CAA)</option>
                         <option>Director Air Int</option>
                         <option>D OPS Training</option>
                         <option>DDATS</option>
                         <option>Ag D Strike Operations</option>
                         <option>DAS</option>
                         <option>DDATO</option>
                         <option>SOO</option>
                         <option>DD OPS Admin</option>
                         <option>ON Course</option>
                         <option>DDAD</option>
                         <option>DDGD</option>
                         <option>D DAI</option>
                         <option>DD UAVS</option>
                         <option>DD DOTS</option>
                         <option>D SOO</option>
                         <option>D DAS</option>
                         <option>D DOS</option>
                         <option>DD DUNO</option>
                         <option>OPS Assistant</option>
                         <option>OPS ASST GRD</option>
                         
                            </select>
                  </div>
                  </MDBCol>
                </MDBRow>


        <MDBRow>
          <MDBCol col='4'>
            <div class="md-form md-outline">
                <textarea class="form-control"  onChange={e => setComments(e.target.value)} value={comments}></textarea>
                <label for="f2" class="">Brief Comments <span class="fa fa-star red-text"></span></label>
                </div>
                </MDBCol>

                </MDBRow>
             
<div className='row'>
              <button className='col-12 btn btn-primary' 
              onClick={handleSubmit} disabled={
                comments === '' || destination === ''                   
                }>
                Send
                </button>
                </div>

                <MDBRow>
        <MDBCol class="12">
        <span class="fa fa-star red-text 3x text-left mt-5"></span> -: Should Not Be Empty
        </MDBCol>
        </MDBRow>

             </MDBCardBody>
          </MDBCard>

        </MDBCol>

      </MDBRow>

    </MDBContainer>
  );
}

export default NewMessage;